/* Global styles form */
.container_1440 {
  width: 100%;
  padding: 1.6rem;
  max-width: 1440px;
  margin: 0 auto;
}

.container_login_form {
  display: flex;
  margin-top: 22rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* Styles form */

.login_form {
  width: 100%;
  max-width: 40rem;
}

.login_form_logo {
  width: 100%;
  max-width: 40rem;
  margin-bottom: 1.6rem;
}

.login_text {
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.inputs_login_form {
  height: 4rem;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  box-sizing: border-box;
  border-radius: 0.5rem;
}

.inputs_login_form:hover,
.inputs_login_form:focus {
  border-color: #7d7d7d !important;
}

.checkbox_text {
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: rgba(0, 0, 0, 0.65);
}

.space_item {
  margin-bottom: 1rem;
}

.login_form_forgot {
  color: #3399cc;
  text-decoration: underline;
}

.login_form_submit {
  height: 4rem;
  color: #fff;
  background-color: #5bbcf7;
  border-radius: 0.5rem;
}

.login_form_submit:focus {
  color: #fff;
  background-color: #5bbcf7;
}

.login_form_submit:hover {
  color: #fff;
  background-color: #5bbcf7;
}
