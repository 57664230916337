/* contenedores */

.container_bg {
  background-color: #fff;
}

.container_1440 {
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
}

.container_background {
  height: 543px;
  cursor: pointer;
  background-color: #000000;
}

.container_border {
  display: flex;
  align-items: center;
}

.container_block_three {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7.1rem 0 6.4rem 2.4rem;
}

.container_block_medium {
  width: 100%;
  height: 54.3rem;
  object-fit: contain;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container_title {
  width: 100%;
  margin: auto;
  padding: 1rem;
  max-width: 40.1rem;
  text-align: center;
  margin-bottom: 4.3rem;
}

.container_parrafo {
  width: 100%;
  margin: auto;
  padding: 1rem;
  max-width: 31.4rem;
}

/* estilos */

.border {
  width: 0.7rem;
  height: 2.6rem;
  border-radius: 0.6rem;
  margin-right: 1rem;
}

.title_block {
  font-family: "Gunterhaus";
  font-size: 3rem;
  color: #fff;
  line-height: 2.7rem;
}

.title {
  font-weight: bold;
  font-size: 3.8rem;
  line-height: 3.5rem;
  color: #ffffff;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.signature {
  font-size: 1.1rem;
  line-height: 1.3rem;
  color: #ffffff;
  margin-bottom: 1rem;
}

.subtitle {
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: #ffffff;
  text-align: center;
}

.reading {
  font-size: 1rem;
  line-height: 1.3rem;
  color: #ffffff;
}
