/* Styles content */

.container_seccion {
  width: 100%;
  height: 100%;
}

.container_table {
  width: 100%;
  height: 60rem;
  overflow: auto;
  padding-top: 1rem;
}

.container_table::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

.select_seccion {
  width: 100%;
  color: #ababab !important;
}

.option_select {
  color: #ababab !important;
  background: #fff !important;
}

/* background of the scrollbar except button or resizer */
.container_table::-webkit-scrollbar-track {
  background-color: #fff;
}
.container_table::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.container_table::-webkit-scrollbar-thumb {
  background-color: #5bbcf7;
  border-radius: 16px;
  border: 5px solid #fff;
}
.container_table::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

.text_seccion {
  color: #ababab;
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.text_seccion_actual {
  color: #7d7d7d;
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: bold;
  padding-bottom: 1rem;
}

.line_seccion {
  width: 100%;
  padding-bottom: 1rem;
  margin-top: 5.6rem;
  border-bottom: 0.5px solid #d9d9d9;
}

.btnSeccion {
  width: 100%;
  height: 3.9rem;
  color: #fff;
  max-width: 17rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.booking_information_table {
  :global {
    .ant-table-thead > tr > th {
      background-color: #f9f9f9;
      color: #7d7d7d;
      font-weight: bold;
    }
    .ant-table-tbody > tr > td {
      color: #7d7d7d;
    }
  }
}

.icons_table {
  width: 100%;
  cursor: pointer;
  max-width: 1.4rem;
  margin-left: 1rem;
}

/* Styles modal create seccions */

.container_content_modal {
  display: flex;
  flex-direction: column;
  padding: 2.8rem 1rem 0 2.1rem;
}

.space_item {
  margin-bottom: 1rem;
}

.title_seccion {
  color: #7d7d7d;
  font-size: 1.6rem;
  font-weight: 600;
}

.title_seccion_editar {
  color: #7d7d7d;
  font-size: 3rem;
  font-weight: bold;
}

.radio_seccion {
  color: #7d7d7d;
  font-size: 1.7rem;
}

.text_seccion {
  color: #7d7d7d;
  font-size: 1.6rem;
  padding-bottom: 1rem;
}

.input_seccion {
  height: 3.2rem;
  background: #ffffff;
  border-radius: 0.5rem;
  box-sizing: border-box;
  border: 1px solid #7d7d7d;
}

.input_seccion:hover,
.input_seccion:focus {
  border-color: #7d7d7d !important;
}

.save_button_seccion {
  width: 100%;
  color: #fff;
  height: 3.2rem;
  box-shadow: none;
  font-size: 1.4rem;
  font-weight: 600;
  max-width: 13.4rem;
  margin-right: 1.6rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.save_button_seccion:hover {
  color: #5bbcf7;
  background: #ffffff;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button_seccion {
  width: 100%;
  height: 3.2rem;
  color: #5bbcf7;
  box-shadow: none;
  font-size: 1.4rem;
  font-weight: 600;
  max-width: 13.4rem;
  background: #ffffff;
  border-radius: 0.5rem;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button_seccion:hover {
  background-color: #5bbcf7;
  color: #fff;
}

.pagination {
  color: #7d7d7d;
  font-size: 1.4rem;
}

.container_options {
  display: flex;
  gap: 2rem;
}

.container_search_table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  width: 100%;
  display: flex;
  color: #fff;
  height: 3.4rem;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 18.8rem;
  align-items: center;
  margin-right: 1.6rem;
  border-radius: 0.5rem;
  justify-content: center;
  background-color: #5bbcf7;
}

/* Form */
.custom_form_item {
  margin-bottom: 0 !important;
}
