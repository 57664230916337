.container_relacionada {
  display: flex;
  gap: 2rem;
}

.text_seccion {
  color: #ababab;
  font-size: 1.2rem;
  line-height: 1.2rem;
  text-transform: uppercase;
}

.container_seccion {
  width: 100%;
}

.parrafo_seccion {
  color: #7d7d7d;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.subparrafo_seccion {
  color: #7d7d7d;
  display: none;
  font-size: 1.6rem;
  line-height: 1.6rem;
}

.noticias_relacionadas {
  color: #7d7d7d;
  font-size: 2.4rem;
  line-height: 2.7rem;
  margin-bottom: 1.1rem;
  font-family: Gunterhaus;
}

.hr_spacing_relacionada {
  display: none;
  margin: 1rem 0rem;
}

.hr_spacing {
  display: block;
  margin: 3rem 0rem;
}

.spacing {
  margin-bottom: 2.2rem;
}

.container_image_relacionada {
  width: 100%;
  max-width: 20.8rem;
}

.container_image_relacionada_dos {
  width: 100%;
  max-width: 20.8rem;
}

/* Responsive */

@media (min-width: 576px) {
  .container_relacionada {
    flex-direction: column;
  }

  .subparrafo_seccion {
    display: block;
  }

  .container_image_relacionada {
    max-width: 23.8rem;
  }

  .container_image_relacionada_dos {
    max-width: 23.8rem;
  }
}

@media (min-width: 768px) {
  .parrafo_seccion {
    margin-bottom: 2.4rem;
  }

  .container_image_relacionada {
    max-width: 100%;
  }

  .container_image_relacionada_dos {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container_image_relacionada_dos {
    display: none;
  }

  .hr_spacing_relacionada {
    display: block;
  }

  .hr_spacing {
    display: none;
  }
}
