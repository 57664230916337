/* Styles global new notice */

.container_menu {
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}

.container_new_news {
  width: 100%;
  padding: 0.9rem;
}

.line_new_news {
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 0.5px solid #d9d9d9;
}

.text_new_news {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding-bottom: 1rem;
}

/* Styles content menu */

.select_seccion {
  width: 100%;
  color: #ababab !important;
}

.option_select {
  color: #ababab !important;
  background: #fff !important;
}

.option_select:hover {
  background: #f6f6f6 !important;
}

.container_button {
  padding: 1rem 0 0 0;
}

.save_button_notices {
  color: #fff;
  height: 3.2rem;
  box-shadow: none;
  font-size: 1.4rem;
  margin-right: 1.6rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.save_button_notices:hover {
  color: #5bbcf7;
  background: #ffffff;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button_notices {
  height: 3.2rem;
  color: #5bbcf7;
  box-shadow: none;
  background: #ffffff;
  border-radius: 0.5rem;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button_notices:hover {
  background-color: #5bbcf7;
  color: #fff;
}

.inputs {
  height: 4rem;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  box-sizing: border-box;
  border-radius: 0.5rem;
}

.inputs_select {
  width: 35rem !important;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  margin: 0.5rem 0 1rem 0;
}

.inputs_select:hover,
.inputs_select:focus {
  border-color: #7d7d7d !important;
}

.inputs:hover,
.inputs:focus {
  border-color: #7d7d7d !important;
}

/* Styles upload */

.container_upload {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 1rem 0;
}

.span_text {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 0 7.5rem 1rem 2.5rem;
}

.span_text_cargar {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 0rem 14.5rem 1rem 0rem;
}

.text_color {
  color: #7d7d7d;
  font-size: 1.4rem;
}

.text_color_switch {
  color: #7d7d7d;
  font-size: 1.4rem;
  padding-right: 0.5rem;
}

.text_upload {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-align: center;
  padding: 0.9rem 2.5rem 1rem 2.5rem;
}

.edit_tex_notices {
  color: rgba(0, 0, 0, 0.25);
  font-size: 1.4rem;
  line-height: 2.4rem;
}

/* Styles selects */

.container_search {
  display: flex;
  align-items: center;
  margin: 1rem 0 0 3rem;
}

.container_selects {
  display: flex;
  align-items: center;
  margin: 1rem 3rem 0 3rem;
}

.spacing_select_fecha {
  width: 14.3rem !important;
  margin-right: 1rem;
}

.spacing_select_claves {
  width: 18.3rem !important;
  margin-right: 1rem;
}

.iconClose {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
  margin-top: 1rem;
}

.spacing_select_seccion {
  width: 15.3rem !important;
  margin-right: 1rem;
}

.search_select {
  width: 30rem !important;
  background: #ffffff;
  margin: 0.5rem 0 1rem 0;
}

.dropdown_select {
  width: 571px !important;
}

.search_button {
  width: 100%;
  max-width: 8.9rem;
  height: 3.4rem;
  color: #fff;
  box-shadow: none;
  font-size: 1.4rem;
  font-weight: 600;
  margin-left: 1.6rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

/* style collapse */

.padding_subcollapse {
  :global {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }
}
.padding_subcollapse_with {
  :global {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 16px;
    }
  }
}

.container_buttons {
  display: flex;
  margin-bottom: 2rem;
}

:global {
  .ant-radio-button-wrapper {
    max-width: 120px;
    width: 100%;
    max-height: 140px;
    height: 100%;
    left: 0 !important;
    padding: 0;
  }

  .ant-radio-button-wrapper span {
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  span .ant-image {
    display: flex !important;
    align-items: center;
    padding: 0px 12px 0px 12px;
    height: 100px;
  }
}

.text_module {
  text-align: center;
}

.w_img {
  width: 80px !important;
}

.header_panel_children {
  background-color: #f6f6f6;
  margin-bottom: 1rem;
}

:global {
  .ant-collapse-content-box {
    background-color: #fff !important;
  }
}

.container_button_table_notices {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

/* custom drawer */
.custom_drawer {
  background-color: transparent;
  left: 0;
  top: auto;
  z-index: 1;

  :global {
    .ant-drawer-body {
      flex-grow: 1;
      margin: 0rem 0rem 0rem 0rem;
      padding: 0rem 0rem 0rem 0rem !important;
      overflow: auto;
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
    }

    .ant-drawer-header {
      position: relative !important;
      display: flex !important;
      bottom: -45px !important;
      left: -10px !important;
      align-items: center;
      justify-content: flex-end !important;
      padding: 0rem !important;
      color: rgba(0, 0, 0, 0.85) !important;
      background: none !important;
    }

    .ant-drawer-header-title {
      justify-content: flex-end !important;
    }

    .ant-drawer-mask {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 0 !important;
      opacity: 0 !important;
      pointer-events: none !important;
      transition: all 2s ease !important;
      -o-transition: all 2s ease !important;
      -ms-transition: all 2s ease !important;
      -moz-transition: all 2s ease !important;
      -webkit-transition: all 2s ease !important;
    }

    .ant-drawer.ant-drawer-open .ant-drawer-mask {
      height: 100% !important;
      transition: all 3s ease !important;
      -webkit-transition: all 2s ease-in-out !important;
      animation: none !important;
      pointer-events: all !important;
    }

    .ant-drawer-left,
    .ant-drawer-right {
      left: 0;
      top: auto;
    }

    .ant-drawer-content {
      position: relative !important;
      z-index: 1 !important;
      overflow: auto !important;
      background-color: #fff !important;
      background-clip: padding-box !important;
      border: 0 !important;
      scrollbar-color: #5bbcf7 #fff !important;
      scrollbar-width: thin !important;
    }
  }
}

/* custom drawer options */
/* custom drawer */
.custom_drawer_options {
  background-color: transparent;
  top: auto;
  z-index: 1;

  :global {
    .ant-drawer-body {
      flex-grow: 1;
      margin: 1rem 1rem 1rem 1rem;
      padding: 0rem 0rem 0rem 0rem !important;
      overflow: auto;
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
    }

    .ant-drawer-header {
      position: relative !important;
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
      padding: 1.6rem 1rem !important;
      color: rgba(0, 0, 0, 0.85) !important;
      background: #fff !important;
      border-bottom: 1px solid #f0f0f0 !important;
      border-radius: 2px 2px 0 0 !important;
      left: auto !important;
      bottom: auto !important;
    }

    .ant-drawer-header-title {
      justify-content: flex-end !important;
    }

    .ant-drawer-mask {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 0 !important;
      opacity: 0 !important;
      pointer-events: none !important;
      transition: all 2s ease !important;
      -o-transition: all 2s ease !important;
      -ms-transition: all 2s ease !important;
      -moz-transition: all 2s ease !important;
      -webkit-transition: all 2s ease !important;
    }

    .ant-drawer.ant-drawer-open .ant-drawer-mask {
      height: 100% !important;
      transition: all 3s ease !important;
      -webkit-transition: all 2s ease-in-out !important;
      animation: none !important;
      pointer-events: all !important;
    }

    .ant-drawer-content {
      position: relative !important;
      z-index: 1 !important;
      overflow: auto !important;
      background-color: #fff !important;
      background-clip: padding-box !important;
      border: 0 !important;
      scrollbar-color: #5bbcf7 #fff !important;
      scrollbar-width: thin !important;
    }
  }
}

/* btn */
.button_add_blocks {
  width: 100%;
  display: flex;
  color: #fff;
  height: 3.4rem;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 15.8rem;
  align-items: center;
  border-radius: 0.5rem;
  justify-content: center;
  background-color: #5bbcf7;
  margin: 1rem;
}
