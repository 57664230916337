.spacingModule {
  margin-bottom: 6.3rem;
}
/* container */
.container_bg {
  background-color: #fff;
}

.container_1060 {
  width: 100%;
  margin: 0 auto;
  max-width: 1060px;
  background-color: #fff;
}

.news_img {
  width: 100%;
  background-color: beige;
  height: 14rem;
  border-radius: 6px;
  margin-bottom: 2rem;
  background-size: cover;
}

.container_span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.7rem;
}

.text_section {
  font-size: 1.2rem;
  color: #d1d1d1;
  line-height: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.title {
  font-size: 2rem;
  color: #6a6a6a;
  font-weight: bold;
  line-height: 2.34rem;
  margin-bottom: 2rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.line_section {
  margin: 1rem 0;
  //max-height: 30rem;
  border: 0.4px solid #6a6a6a;
}

.title_module {
  font-family: "Gunterhaus";
  font-size: 3rem;
  color: #103783;
  line-height: 2.7rem;
}
