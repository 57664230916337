/* container */

.container_bg {
  background-color: #fff;
}

.container_1440 {
  width: 100%;
  padding: 1rem;
  margin: 0 auto;
  max-width: 1440px;
  background-color: #f2f2f2;
}

.container_1060 {
  width: 100%;
  margin: 0 auto;
  max-width: 1060px;
  margin-bottom: 4.8rem;
}

.container_title {
  margin-bottom: 1.8rem;
}

.container_video {
  width: 100%;
  height: 100%;
  cursor: pointer;
  object-fit: cover;
  min-height: 37.2rem;
  border-radius: 0.6rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* estilos */

.title_module {
  font-family: "Gunterhaus";
  font-size: 3rem;
  color: #103783;
  line-height: 2.7rem;
}

.title {
  font-size: 3rem;
  color: #bbbbbb;
  font-weight: bold;
  line-height: 3.4rem;
  margin-bottom: 2rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.subtitle {
  font-size: 1.8rem;
  color: #888888;
  line-height: 2rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
}

.reading {
  font-size: 1rem;
  color: #ababab;
  line-height: 1.3rem;
}
