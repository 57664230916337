/* container */
.container_bg {
  background-color: #fff;
}

.container_1060 {
  width: 100%;
  margin: 0 auto;
  max-width: 1060px;
  background-color: #fff;
}

.container_main {
  width: 100%;
  height: 31.1rem;
  cursor: pointer;
  border-radius: 0.6rem;
  background-size: cover;
  background-position: center;
}

.container_main > div {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 3.4rem;
  align-items: center;
  border-radius: 0.6rem;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.08) 43.23%,
    #0000008c 65.62%
  );
}

.divider_container {
  margin-bottom: 3rem;
}

.container_main {
  width: 100%;
  height: 30.8rem;
  object-fit: cover;
  border-radius: 0.6rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container_span {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.container_text_card {
  padding: 1rem;
}

.container_card {
  width: 100%;
  cursor: pointer;
  height: 34.2rem;
  border-radius: 0.6rem;
  box-shadow: 0 1.1rem 1.7rem rgba(0, 0, 0, 0.08);
}

.container_img {
  width: 100%;
  height: 23.7rem;
  cursor: pointer;
  object-fit: cover;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
  background-size: cover;
  background-position: center;
}

.divider_container {
  margin-bottom: 3rem;
}

/* estilos */

.line_section {
  margin: 1rem 0;
  border: 0.5px solid #6a6a6a;
}

.title_module {
  font-family: "Gunterhaus";
  font-size: 3rem;
  color: #103783;
  line-height: 2.7rem;
}

.text_module {
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.reading_module {
  color: #fff;
  font-size: 1rem;
}

.text_main {
  color: #d1d1d1;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.reading_main {
  color: #d1d1d1;
  font-size: 1rem;
}

.title_main {
  font-size: 3rem;
  color: #ffffff;
  font-weight: bold;
  line-height: 3.4rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.title {
  font-size: 2rem;
  color: #6a6a6a;
  font-weight: bold;
  line-height: 2.3rem;
  margin-bottom: 1rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.subtitle {
  color: #6a6a6a;
  font-size: 1.6rem;
  line-height: 2.3rem;
  margin-bottom: 1rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.title_main_two {
  color: #7d7d7d;
  font-size: 3rem;
  font-weight: bold;
  line-height: 3.4rem;
  margin-bottom: 2rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.subtitle_main {
  color: #7d7d7d;
  font-size: 1.6rem;
  line-height: 2rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.title_card {
  color: #7d7d7d;
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.3rem;
  margin-bottom: 1rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.subtitle_card {
  color: #7d7d7d;
  font-size: 1.6rem;
  line-height: 2.3rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
