.container {
  position: fixed;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100vh;
  background-color: #00000020;
  align-items: center;
}
