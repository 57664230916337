.container_signature {
  width: 100%;
  height: 100%;
  /* padding: 1.6rem; */
}

.line {
  width: 100%;
  padding-bottom: 1rem;
  margin-top: 5.6rem;
  border-bottom: 0.5px solid #d9d9d9;
}

.container_header_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container_search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.container_options {
  display: flex;
  gap: 2rem;
}

.text_search {
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: #7d7d7d;
}

.pagination {
  color: #7d7d7d;
  font-size: 1.4rem;
}

.container_table {
  width: 100%;
  height: 60rem;
  overflow: auto;
  padding-top: 1rem;
}

.container_table::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.container_table::-webkit-scrollbar-track {
  background-color: #fff;
}
.container_table::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.container_table::-webkit-scrollbar-thumb {
  background-color: #5bbcf7;
  border-radius: 16px;
  border: 5px solid #fff;
}
.container_table::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

.input_search {
  width: 16.7rem;
  height: 2.9rem;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  box-sizing: border-box;
  border-radius: 0.5rem;
}

.input {
  width: 100%;
  height: 2.9rem;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  box-sizing: border-box;
  border-radius: 0.5rem;
}

.search_button {
  width: 100%;
  color: #fff;
  height: 3.2rem;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 13.4rem;
  margin-right: 1.6rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.search_button:hover {
  color: #5bbcf7;
  background: #ffffff;
  border: 0.1rem solid #5bbcf7;
}

.button_signature {
  width: 100%;
  display: flex;
  color: #fff;
  height: 3.4rem;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 15.8rem;
  align-items: center;
  margin-right: 1.6rem;
  border-radius: 0.5rem;
  justify-content: center;
  background-color: #5bbcf7;
}

.container_content_modal {
  display: flex;
  flex-direction: column;
  padding: 2.8rem 1rem 0 2.1rem;
}

.title_table {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #7d7d7d;
  margin-bottom: 1.7rem;
}

.text_table {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #7d7d7d;
}

.line_table_modal {
  width: 100%;
  margin: 2.3rem 0 1.6rem;
  border-bottom: 0.5px solid #d9d9d9;
}

.container_option_home {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.text_table_option {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 1.8rem;
  padding-right: 15.1rem;
}

.information_table {
  :global {
    .ant-table-thead > tr > th {
      background-color: #f9f9f9;
      color: #7d7d7d;
      font-weight: bold;
    }
    .ant-table-tbody > tr > td {
      color: #7d7d7d;
    }
  }
}

.icons_table {
  width: 100%;
  cursor: pointer;
  max-width: 1.4rem;
  margin-left: 1rem;
}

.container_fotografia {
  width: 100%;
  height: 5.4rem;
  max-width: 5.4rem;
}

.container_upload {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.span_text {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding-right: 15rem;
  padding-bottom: 1rem;
}

.text_upload {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-align: center;
  padding: 0.9rem 2.5rem 1rem 2.5rem;
}

.container_button {
  display: flex;
  gap: 2rem;
  padding-top: 1rem;
}

.save_button {
  width: 100%;
  color: #fff;
  height: 3.2rem;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 13.4rem;
  margin-right: 1.6rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.save_button:hover {
  color: #5bbcf7;
  background: #ffffff;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button {
  width: 100%;
  height: 3.2rem;
  color: #5bbcf7;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 13.4rem;
  background: #ffffff;
  border-radius: 0.5rem;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button:hover {
  background-color: #5bbcf7;
  color: #fff;
}
