/* Styles alerta succes */

.container_alert_success {
  width: 100% !important;
  max-width: 49.1rem !important;
  min-height: 21rem !important;
  background-color: #103783 !important;
}

.title_alert_success {
  font-size: 3rem !important;
  font-weight: bold !important;
  color: #fff !important;
  line-height: 3.2rem !important;
  padding: 7.3rem 11.3rem 1rem 11.3rem !important;
}

.html_container_success {
  margin: 0 !important;
  color: #fff !important;
  font-size: 1.5rem !important;
  font-weight: 600 !important;
  padding: 1rem 11.14rem 6.3rem 11.14rem !important;
}

.closeButton_success {
  box-shadow: none !important;
}

/* Styles alert question succes */

.container_alert_question {
  width: 100% !important;
  max-width: 37.4rem !important;
  height: 19rem !important;
}

.title_alert_question {
  font-size: 1.6rem !important;
  color: #7d7d7d !important;
  line-height: 2.2rem !important;
  padding: 5.1rem 3.8rem 0 3.8rem !important;
}

.actionsQuestion {
  width: 100% !important;
}

.confirmButtonQuestion {
  width: 100% !important;
  color: #fff !important;
  max-width: 13.4rem !important;
  font-size: 1.4rem !important;
  border-radius: 0.5rem !important;
  background-color: #5bbcf7 !important;
  box-shadow: none !important;
  padding: 0.5rem 3.9rem 0.5rem 3.9rem !important;
}

.confirmButtonQuestion:hover {
  color: #5bbcf7 !important;
  background: #ffffff !important;
  border: 0.1rem solid #5bbcf7 !important;
}

.cancelButtonQuestion {
  width: 100% !important;
  max-width: 13.4rem !important;
  background: #ffffff !important;
  font-size: 1.4rem !important;
  height: 3.2rem !important;
  color: #5bbcf7 !important;
  border: 0.1rem solid #5bbcf7 !important;
  border-radius: 0.5rem !important;
  box-shadow: none !important;
  padding: 0.5rem 3.9rem 0.5rem 3.9rem !important;
}

.cancelButtonQuestion:hover {
  background-color: #5bbcf7 !important;
  color: #fff !important;
}

/* Styles alert error */

.container_alert_error {
  width: 100% !important;
  max-width: 49.1rem !important;
  min-height: 21rem !important;
  background-color: #fff1f0 !important;
}

.title_alert_error {
  font-size: 3rem !important;
  color: #7d7d7d !important;
  font-weight: bold !important;
  padding: 0 !important;
}

.html_container_error {
  font-size: 1.6rem !important;
  color: #7d7d7d !important;
  padding: 1rem 6.8rem 1rem 6.8rem !important;
}
