/* contenedores */

.container_bg {
  background-color: #fff;
}

.container_1060 {
  width: 100%;
  margin: 0 auto;
  max-width: 1060px;
  background-color: #fff;
}

.container_block {
  width: 100%;
  height: 36rem;
  cursor: pointer;
  object-fit: cover;
  border-radius: 0.6rem;
  background-size: cover;
  background-position: center;
}

.container_block > div {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 3.4rem;
  align-items: center;
  border-radius: 0.6rem;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.08) 43.23%,
    #0000008c 65.62%
  );
}

.container_block_two {
  width: 100%;
  height: 21.8rem;
  cursor: pointer;
  object-fit: contain;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
  background-size: cover;
  background-position: center;
}

.container_block_three {
  width: 100%;
  height: 20.2rem;
  cursor: pointer;
  object-fit: contain;
  margin-bottom: 1.4rem;
  border-radius: 0.6rem;
  background-size: cover;
  background-position: center;
}

.container_title_border {
  display: flex;
  align-items: center;
}

.container_span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container_info_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container_avatar {
  flex: 0 0 auto;
  width: auto;
  padding-right: 0.6rem;
}

.container_tex_card {
  display: flex;
  flex: 1 0 0%;
  flex-direction: column;
}

.container_info {
  display: flex;
  flex-direction: column;
}

/* estilos */

.order_module {
  display: flex;
  row-gap: 3.1rem;
  flex-direction: column;
}

.borde {
  width: 0.7rem;
  height: 2.6rem;
  border-radius: 0.6rem;
  margin-right: 1rem;
}

.line_section {
  margin: 1rem 0;
  border: 0.5px solid #6a6a6a;
}

.title_module {
  font-family: "Gunterhaus";
  font-size: 3rem;
  color: #103783;
  line-height: 2.7rem;
}

.title_main {
  font-size: 3rem;
  color: #ffffff;
  font-weight: bold;
  line-height: 3.2rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text_main {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.reading_main {
  color: #ffffff;
  font-size: 1rem;
}

.title_section {
  font-family: "Gunterhaus";
  font-size: 3rem;
}

.text_section {
  font-size: 1.2rem;
  color: #d1d1d1;
  line-height: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.reading_section {
  font-size: 1rem;
  color: #ababab;
  line-height: 1.3rem;
  margin-bottom: 0.6rem;
}

.title_block {
  color: #7d7d7d;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.9rem;
  margin-bottom: 0.5rem;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.subtitle_block {
  color: #7d7d7d;
  font-size: 1.5rem;
  line-height: 1.9rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text_signature {
  font-size: 1.1rem;
  color: #7d7d7d;
  font-weight: bold;
  line-height: 1.3rem;
}

.date_card {
  font-size: 1.1rem;
  color: #7d7d7d;
  line-height: 1.3rem;
}

.reading_section_card {
  font-size: 1rem;
  color: #ababab;
  line-height: 1.3rem;
}

.description_section {
  color: #7d7d7d;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.8rem;
  margin-bottom: 2rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.avatar_module {
  width: 3.9rem;
  height: 3.9rem;
  object-fit: contain;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.card_module {
  width: 100%;
  height: 100%;
  cursor: pointer;
  max-height: 30rem;
  border-radius: 0.6rem;
  box-shadow: 0 1.1rem 1.7rem rgba(0, 0, 0, 0.08);
}

/* Responsive */
