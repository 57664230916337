.container_seleccionar_noticia {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container_selects {
  display: flex;
  align-items: center;
  margin: 1rem 3rem 0 3rem;
}

.container_search {
  display: flex;
  align-items: center;
  margin: 1rem;
  gap: 1rem;
}

.spacing_item {
  margin-bottom: 0 !important;
}

.span_select {
  font-size: 1.3rem;
  color: #7d7d7d;
  line-height: 19px;
}

.inputs_select {
  width: 35rem !important;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  margin: 0.5rem 0 1rem 0;
}

.inputs_select:hover,
.inputs_select:focus {
  border-color: #7d7d7d !important;
}

.dropdown_select {
  width: 571px !important;
}

.spacing_select {
  width: 100% !important;
}

.select_seccion {
  width: 100%;
  color: #ababab !important;
}

.option_select {
  color: #ababab !important;
  background: #fff !important;
}

.option_select:hover {
  background: #f6f6f6 !important;
}

.spacing_select_claves {
  width: 18.3rem !important;
  margin-right: 1rem;
}

.iconClose {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
  margin-top: 1rem;
}

.search_select {
  width: 100% !important;
  background: #ffffff;
}

.search_button {
  width: 40%;
  height: 3.4rem;
  color: #fff;
  box-shadow: none;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.notice_title {
  color: #7d7d7d;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 1.9rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* tablet */
.container_table {
  width: 100%;
  height: 60rem;
  overflow: auto;
  padding-top: 1rem;
}

.container_table::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* scrollbar itself */
.container_table::-webkit-scrollbar-thumb {
  background-color: #5bbcf7;
  border-radius: 16px;
  border: 5px solid #fff;
}
.container_table::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* button */

.container_buttons {
  display: flex;
  gap: 1rem;
  margin: 1rem;
}

.save_button_notices {
  color: #fff;
  height: 3.2rem;
  width: 100%;
  box-shadow: none;
  font-size: 1.4rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.save_button_notices:hover {
  color: #5bbcf7;
  background: #ffffff;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button_notices {
  height: 3.2rem;
  width: 100%;
  color: #5bbcf7;
  box-shadow: none;
  background: #ffffff;
  border-radius: 0.5rem;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button_notices:hover {
  background-color: #5bbcf7;
  color: #fff;
}
