.container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container_img {
  width: 100%;
}


.container_text {
  width: 100%;
  display: flex;
  margin: auto;
  height: 100%;
  max-width: 26.1rem;
  align-items: center;
  text-align: center;
  flex-direction: column;
  justify-content: center;
}

.numero_404 {
  color: #5bbcf7;
  font-weight: bold;
  font-size: 10.5rem;
  line-height: 10.5rem;
}

.text_404 {
  color: #7d7d7d;
  font-weight: bold;
  font-size: 3rem;
}
