/* container */

.container_bg {
  background-color: #fff;
}

.container_1060 {
  width: 100%;
  margin: 0 auto;
  max-width: 1060px;
  background-color: #fff;
}

.container_card {
  cursor: pointer;
  border-radius: 6px;
  padding: 4rem 2.6rem;
  background: #f9f9f9;
  margin-bottom: 1.8rem;
  box-shadow: 0px 11px 17px rgba(0, 0, 0, 0.08);
}

.container_text_card {
  width: 100%;
  margin: auto;
  max-width: 25.6rem;
}

.container_span {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  justify-content: space-between;
}

.container_video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 24.9rem;
  cursor: pointer;
  border-radius: 0.6rem;
  margin-bottom: 2.3rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container_video_two{
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  max-height: 21.8rem;
  border-radius: 0.6rem;
  margin-bottom: 1rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* estilos */

.line_section {
  margin: 1rem 0;
  border: 0.5px solid #6a6a6a;
}

.title_module {
  font-family: "Gunterhaus";
  font-size: 3rem;
  color: #103783;
  line-height: 2.7rem;
}

.title {
  color: #7d7d7d;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.title_two {
  color: #7d7d7d;
  font-size: 2rem;
  font-weight: bold;
  line-height: 2rem;
  overflow: hidden;
  margin-bottom: 1rem;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.subtitle_two {
  color: #7d7d7d;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text_main {
  color: #d1d1d1;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.reading_main {
  color: #d1d1d1;
  font-size: 1rem;
}

.title_card {
  color: #7d7d7d;
  font-size: 3rem;
  font-weight: bold;
  line-height: 3rem;
  margin-bottom: 1rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.subtitle_card {
  color: #7d7d7d;
  font-size: 1.6rem;
  line-height: 1.4rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
