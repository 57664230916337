/* container */

.container_bg {
    background-color: #fff;
  }
  
  .container_1060 {
    width: 100%;
    margin: 0 auto;
    max-width: 1060px;
    margin-bottom: 4.8rem;
  }
  
  .container_span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  

  .container_video{
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 24.9rem;
  
  cursor: pointer;
  border-radius: 0.6rem;
  margin-bottom: 2.3rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
  .container_card {
    width: 100%;
    height: 100%;
    cursor: pointer;
    max-height: 36.2rem;
    border-radius: 0.6rem;
    box-shadow: 0 1.1rem 1.7rem rgba(0, 0, 0, 0.08);
  }
  
  .container_card_text {
    padding: 2rem;
  }

  
  /* :global .ant-card-body {
    padding: 0;
  }
  
  :global .ant-card {
    background-color: #0d3650;
  }
  
  :global .ant-card-bordered .ant-card-cover > img {
    width: 100%;
    height: 23.4rem;
    object-fit: cover;
  } */
  
  /* estilos */
  
  .line_section {
    margin: 1rem 0;
    border: 0.5px solid #6a6a6a;
  }
  
  .title_module {
    font-family: "Gunterhaus";
    font-size: 3rem;
    color: #103783;
    line-height: 2.7rem;
  }
  
  .text_section {
    font-size: 1.2rem;
    color: #d1d1d1;
    line-height: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .reading_section {
    font-size: 1rem;
    color: #ababab;
    line-height: 1.3rem;
    margin-bottom: 0.6rem;
  }
  
  .title {
    font-size: 3rem;
    color: #6a6a6a;
    font-weight: bold;
    line-height: 3.4rem;
    margin-bottom: 2rem;
    overflow: hidden;
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  
  .subtitle {
    font-size: 1.8rem;
    color: #6a6a6a;
    line-height: 2rem;
    margin-bottom: 2.5rem;
    overflow: hidden;
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  
  .title_two {
    font-size: 2rem;
    color: #6a6a6a;
    font-weight: bold;
    line-height: 2.3rem;
    margin-bottom: 1rem;
    overflow: hidden;
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  
  .subtitle_two {
    font-size: 1.6rem;
    color: #6a6a6a;
    line-height: 1.5rem;
    overflow: hidden;
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
  
  .title_card {
    color: #6a6a6a;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: .5rem;
    overflow: hidden;
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .title_card_announcer1 {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: .5rem;
    overflow: hidden;
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .text_card {
    color: #6a6a6a;
    font-size: 1.6rem;
    line-height: 2rem;
    overflow: hidden;
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .text_card_announcer1 {
    color: #ffffff;
    font-size: 1.6rem;
    line-height: 2rem;
    overflow: hidden;
    display: -webkit-box;
    overflow-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }