/* container */

.container_bg {
  background-color: #fff;
}

.container_1060 {
  width: 100%;
  margin: 0 auto;
  max-width: 1060px;
  background-color: #f2f2f2;
}

.container_module {
  width: 100%;
  margin: 0 auto;
  padding: 3rem 0;
  max-width: 95.2rem;
}

.container_card {
  width: 100%;
  cursor: pointer;
  height: 30rem;
  border-radius: 0.6rem;
  box-shadow: 0 1.1rem 1.7rem rgba(0, 0, 0, 0.08);
}

.container_line {
  margin: 2.6rem 0;
}

/* estilos */

.title_module {
  font-family: "Gunterhaus";
  font-size: 3rem;
  color: #103783;
  line-height: 2.7rem;
}

.title_card {
  color: #7d7d7d;
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.2rem;
  margin-bottom: 1rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.subtitle_card {
  color: #7d7d7d;
  font-size: 1.6rem;
  line-height: 1.4rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

:global .ant-progress-bg {
  height: 2.4rem !important;
}
