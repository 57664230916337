.container_text_banner {
  width: 100%;
  margin: auto;
  padding: 1rem;
  max-width: 95.2rem;
}

.container_banner_text {
  width: 100%;
  max-width: 66.7rem;
}

.container_text_pie {
  width: 100%;
  margin: auto;
  padding: 1rem;
  max-width: 95.2rem;
}

.container_text_foto {
  display: flex;
  margin-left: 0.6rem;
  align-items: center;
}

.container_pie_foto {
  width: 100%;
  height: 25rem;
  max-width: 1440px;
  object-fit: cover;
  margin-bottom: 2.1rem;
  background-position: center;
  background-repeat: no-repeat;
}

.container_publicidad_uno {
  width: 100%;
  margin: 0 auto;
  max-width: 70.28rem;
  margin-bottom: 5.5rem;
}

.container_share {
  gap: 0.5rem;
  display: flex;
  align-items: center;
}

.seccion_banner {
  font-family: "Gunterhaus";
  color: #ababab;
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin-bottom: 2.1rem;
  text-transform: uppercase;
}

.title_banner {
  color: #103783;
  font-size: 3rem;
  line-height: 3.2rem;
  font-weight: bold;
  margin-bottom: 1.3rem;
}

.antetitulo_banner {
  color: #7d7d7d;
  font-size: 2.2rem;
  font-weight: 300;
  line-height: 3.2rem;
  margin-bottom: 2rem;
}

.subtitulo_banner {
  font-weight: 500;
  color: #7d7d7d;
  font-size: 1.8rem;
  line-height: 2rem;
  margin-bottom: 1.6rem;
}

.icon_logo {
  cursor: pointer;
  max-width: min-content;
}

.text_share {
  color: #7d7d7d;
  font-size: 1.5rem;
  line-height: 1.9rem;
}

.line_pie_foto {
  width: 100%;
  border-left: 0.5px solid #ababab;
}

.text_pie_foto {
  color: #ababab;
  font-size: 1.4rem;
  line-height: 1.3rem;
}

/* Styles second banner */

.container_texto_banner {
  width: 100%;
  height: 36.7rem;
  object-fit: cover;
  margin-bottom: 1rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container_texto_banner > div {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 3rem 0rem;
  align-items: center;
  border-radius: 0.6rem;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.08) 43.23%,
    #0000008c 65.62%
  );
}

.antetitulo_second {
  color: #ffffff;
  font-weight: 300;
  font-size: 2.2rem;
  line-height: 3.2rem;
}

.titulo_second {
  color: #ffffff;
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 3.2rem;
}

.container_title_banner_second {
  width: 100%;
  padding: 0rem 3rem;
  max-width: 96.1rem;
}

.container_banner_second {
  width: 100%;
  display: flex;
  margin: auto;
  padding: 1rem;
  max-width: 96.2rem;
}

.container_foto_pie {
  width: 100% !important;
  height: 100% !important;
}

/* styles banner especial */

.container_background {
  height: calc(639px - 260px);
}

.container_banner_especial {
  height: 50rem;
  background-color: #f2f2f2;
}

.container_text_banner_especial {
  gap: 1rem;
  width: 100%;
  margin: auto;
  padding: 3rem;
  display: flex;
  max-width: 98.4rem;
  flex-direction: column;
}

.container_img_especial {
  width: 100%;
  margin: auto auto 9.2rem;
  height: 40rem;
  max-width: 119rem;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.text_seccion {
  font-family: "Gunterhaus";
  font-size: 2.5rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}

.antetitulo_three {
  color: #7d7d7d;
  font-size: 1.5rem;
  line-height: 1.8rem;
}

.titulo_three {
  color: #7d7d7d;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3rem;
}

.subtitulo_three {
  color: #7d7d7d;
  font-size: 1.6rem;
  line-height: 2rem;
}

.entradilla_three {
  color: #7d7d7d;
  font-size: 2.5rem;
  line-height: 2.9rem;
}

/* styles banner announcer*/

.container_img_announcer{
  width: 100%;
  margin: auto auto 1rem ;
  height: 40rem;
  max-width: 119rem;
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
}
/* Responsive */

@media (min-width: 576px) {
  .subtitulo_three {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .container_img_especial {
    height: 50rem;
  }
  .container_img_announcer{
    height: 50rem;
  }
}

@media (min-width: 768px) {
  .container_texto_banner {
    height: 50.8rem;
  }

  .text_seccion {
    font-size: 3rem;
    line-height: 1.5rem;
  }

  .antetitulo_three {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }

  .titulo_second {
    font-size: 4.8rem;
    line-height: 5.2rem;
  }

  .container_texto_banner > div {
    padding: 6rem 0rem;
  }

  .container_text_pie {
    margin-bottom: 4.5rem;
  }

  .subtitulo_banner {
    margin-bottom: 2.6rem;
  }

  .title_banner {
    font-size: 4.8rem;
    line-height: 5.2rem;
  }

  .container_pie_foto {
    height: 40.4rem;
    background-position-y: top;
    background-position-x: center;
  }
}

@media (min-width: 992px) {
  .titulo_three {
    font-size: 4.8rem;
    line-height: 5.2rem;
  }

  .subtitulo_three {
    font-size: 2.5rem;
    line-height: 2.9rem;
  }

  .container_img_especial {
    height: 50rem;
  }
  .container_img_announcer{
    height: 50rem;
  }
}

@media (min-width: 1200px) {
  .container_img_especial {
    height: 70rem;
  }
  .container_img_announcer{
    height: 70rem;
  }
}
