.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 99999999999999;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
