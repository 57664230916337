.text_ther {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #7d7d7d;
}

.text_area_ther {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text_container_ther {
  margin-bottom: 2rem;
}

.color_ther {
  width: 2.9rem;
  height: 2.9rem;
  background-color: #c0c0c0;
  border: 1px solid #7d7d7d;
  margin-right: 0.75rem;
  border-radius: 0.25rem;
  text-align: center;
}
.icon_color_ther {
  color: #ffffff;
  font-size: 2rem;
}
.color_popover {
  display: flex;
}
.span_text_upload {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding-bottom: 1rem;
}
.text_color_ther {
  text-decoration-line: underline;
  color: rgba($color: #000000, $alpha: 0.25);
}

.text_color_sel_ther {
  color: rgba($color: #000000, $alpha: 0.25);
}

.color_container_ther {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}
.save_button {
  width: 100%;
  color: #fff;
  height: 3.2rem;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 13.4rem;
  margin-right: 1.6rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.cancel_button {
  width: 100%;
  height: 3.2rem;
  color: #5bbcf7;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 13.4rem;
  background: #ffffff;
  border-radius: 0.5rem;
  border: 0.1rem solid #5bbcf7;
}

//---------------Preview Styles ----------------------------

.container_bg {
  background-color: #fff;
}

.container_1060 {
  width: 100%;
  margin: 0 auto;
  max-width: 1060px;
  background-color: #f2f2f2;
}

.container_module {
  width: 100%;
  margin: 0 auto;
  padding: 3rem 0;
  max-width: 95.2rem;
}

.container_card {
  width: 100%;
  cursor: pointer;
  height: 30rem;
  border-radius: 0.6rem;
  box-shadow: 0 1.1rem 1.7rem rgba(0, 0, 0, 0.08);
}
.container_line {
  margin: 2.6rem 0;
}

.container_upload {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.title_card {
  color: #7d7d7d;
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.2rem;
  margin-bottom: 1rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.subtitle_card {
  color: #7d7d7d;
  font-size: 1.6rem;
  line-height: 1.4rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.therm {
  width: 100%;
  height: 2.55rem;
  background-color: #5bbcf7;
  display: flex;
  border-radius: 2rem;
  overflow: hidden;
}

.therm1 {
  width: 34%;
  height: 2.55rem;
}
