.select_seccion {
  width: 100%;
  color: #ababab !important;
}

.option_select {
  color: #ababab !important;
  background: #fff !important;
}

.option_select:hover {
  background: #f6f6f6 !important;
}

.container_button_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
}

.save_button {
  width: 100%;
  color: #fff;
  height: 3.2rem;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 13.4rem;
  margin-right: 1.6rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.save_button:hover {
  color: #5bbcf7;
  background: #ffffff;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button {
  width: 100%;
  height: 3.2rem;
  color: #5bbcf7;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 13.4rem;
  background: #ffffff;
  border-radius: 0.5rem;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button:hover {
  background-color: #5bbcf7;
  color: #fff;
}
