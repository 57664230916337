.container_subscribers {
  width: 100%;
  height: 100%;
  /*   padding: 1.6rem;
 */
}

.line {
  width: 100%;
  padding-bottom: 1rem;
  margin-top: 5.6rem;
  border-bottom: 0.5px solid #d9d9d9;
}

.container_header_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button_subscribers {
  width: 100%;
  display: flex;
  color: #fff;
  height: 3.9rem;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 20.8rem;
  align-items: center;
  margin-right: 1.6rem;
  border-radius: 0.5rem;
  justify-content: center;
  background-color: #5bbcf7;
}

.pagination {
  color: #7d7d7d;
  font-size: 1.4rem;
}

.container_table {
  width: 100%;
  height: 60rem;
  overflow: auto;
  padding-top: 1rem;
}

.container_table::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.container_table::-webkit-scrollbar-track {
  background-color: #fff;
}
.container_table::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.container_table::-webkit-scrollbar-thumb {
  background-color: #5bbcf7;
  border-radius: 16px;
  border: 5px solid #fff;
}
.container_table::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

.information_table {
  :global {
    .ant-table-thead > tr > th {
      background-color: #f9f9f9;
      color: #7d7d7d;
      font-weight: bold;
    }
    .ant-table-tbody > tr > td {
      color: #7d7d7d;
    }
  }
}

.button {
  width: 100%;
  display: flex;
  color: #fff;
  height: 3.4rem;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 18.8rem;
  align-items: center;
  margin-right: 1.6rem;
  border-radius: 0.5rem;
  justify-content: center;
  background-color: #5bbcf7;
}
