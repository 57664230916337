/* Style global */

.container {
  display: flex;
  min-height: 100%;
}

/* header */
.header {
  z-index: 9999;
  width: 100%;
  margin: 0 auto;
  padding: 1.6rem;
  background-color: #fff;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.08);
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 100%;
  max-width: 16.5rem;
}

.iconSesion {
  width: 100%;
  height: 2.1rem;
  max-width: 2rem;
}

.cerrarSesion {
  width: 100%;
  display: flex;
  max-width: 30rem;
  align-items: center;
  justify-content: center;
}

.btnCerrarSesion {
  width: 100%;
  height: 3.3rem;
  color: #fff;
  max-width: 12rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.btnCerrarSesion:focus {
  color: #fff;
  background-color: #5bbcf7;
}

.btnCerrarSesion:hover {
  color: #fff;
  background-color: #5bbcf7;
}

.cerrarSesion_text {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 2.2rem;
  padding: 0 1.2rem 0 1.2rem;
}

/* Styles menu sider */

.menuList {
  width: 100%;
  height: calc(100vh - 7.5rem);
  z-index: 999;
  background: #fff;
  max-width: 25.7rem;
  overflow-y: auto;
}

.list {
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.list_item {
  width: 100%;
  list-style: none;
  text-align: center;
}

.list_item:hover {
  background: #103783;
}

.list_item_home {
  list-style: none;
  width: 100%;
  height: 4rem;
  border-top: 0.1rem solid #d9d9d9;
  display: flex;
  align-items: center;
}

.switch {
  margin-right: 1.8rem;
}

.list_item_home:hover {
  background: rgba(91, 188, 247, 0.3);
}

.list_click {
  cursor: pointer;
}

.list_button {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  position: relative;
}

.list_arrow {
  margin-left: auto;
  font-size: 1.6rem;
  position: absolute;
  top: 50%;
  left: 100%;
  transition: height 0.4s;
  transform: translate(-3rem, -50%);
}

.list_link {
  color: #7d7d7d;
  width: 100%;
  height: 100%;
  display: block;
  text-align: start;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-decoration: none;
  padding: 1rem 10.1rem 1rem 4.9rem;
}

.list_link_notice {
  color: #7d7d7d;
  width: 100%;
  height: 100%;
  display: block;
  text-align: start;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-decoration: none;
  padding: 1rem 8.1rem 1rem 4.9rem;
}

.list_sub_link {
  color: #7d7d7d;
  width: 100%;
  height: 100%;
  display: block;
  text-align: start;
  font-size: 1.4rem;
  line-height: 2.2rem;
  text-decoration: none;
  padding: 0.8rem 0 0 6.2rem;
}

.list_sub_link:hover {
  color: #7d7d7d;
}

.list_link:hover {
  color: #fff;
}

.list_link_notice:hover {
  color: #fff;
}

.list_link_inside {
  text-align: left;
  border-radius: 0.6rem;
  padding-left: 2rem;
}

.list_link_inside:hover {
  background: #f6f8fa;
}

.list_show {
  width: 100%;
  height: 0;
  list-style: none;
  margin-left: auto;
  overflow: hidden;
}

.list_show.activate {
  height: 24rem;
}

.ease {
  transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
}

.list_sub {
  width: 100%;
  list-style: none;
  background: #f7f7f7;
  padding: 0;
}

.scroll_outlet {
  width: 100%;
  height: calc(100vh - 7.5rem);
  background: #fff;
  overflow-y: auto;
}
