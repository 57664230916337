.select_seccion {
  width: 100%;
  color: #ababab !important;
}

.option_select {
  color: #ababab !important;
  background: #fff !important;
}

.option_select:hover {
  background: #f6f6f6 !important;
}

.container_button_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
}

.save_button {
  width: 100%;
  color: #fff;
  height: 3.2rem;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 13.4rem;
  margin-right: 1.6rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.save_button:hover {
  color: #5bbcf7;
  background: #ffffff;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button {
  width: 100%;
  height: 3.2rem;
  color: #5bbcf7;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 13.4rem;
  background: #ffffff;
  border-radius: 0.5rem;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button:hover {
  background-color: #5bbcf7;
  color: #fff;
}

/* Custom drawer */
.custom_drawer {
  background-color: transparent;
  left: 0;
  top: auto;
  z-index: 1;

  :global {
    .ant-drawer-body {
      flex-grow: 1;
      margin: 0rem 0rem 0rem 0rem;
      padding: 0rem 0rem 0rem 0rem !important;
      overflow: auto;
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
    }

    .ant-drawer-header {
      position: relative !important;
      display: flex !important;
      bottom: -45px !important;
      left: -10px !important;
      align-items: center;
      justify-content: flex-end !important;
      padding: 0rem !important;
      color: rgba(0, 0, 0, 0.85) !important;
      background: none !important;
    }

    .ant-drawer-header-title {
      justify-content: flex-end !important;
    }

    .ant-drawer-mask {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 0 !important;
      opacity: 0 !important;
      pointer-events: none !important;
      transition: all 2s ease !important;
      -o-transition: all 2s ease !important;
      -ms-transition: all 2s ease !important;
      -moz-transition: all 2s ease !important;
      -webkit-transition: all 2s ease !important;
    }

    .ant-drawer.ant-drawer-open .ant-drawer-mask {
      height: 100% !important;
      transition: all 3s ease !important;
      -webkit-transition: all 2s ease-in-out !important;
      animation: none !important;
      pointer-events: all !important;
    }

    .ant-drawer-left,
    .ant-drawer-right {
      left: 0;
      top: auto;
    }

    .ant-drawer-content {
      position: relative !important;
      z-index: 1 !important;
      overflow: auto !important;
      background-color: #fff !important;
      background-clip: padding-box !important;
      border: 0 !important;
      scrollbar-color: #5bbcf7 #fff !important;
      scrollbar-width: thin !important;
    }
  }
}

/* custom drawer options */
.custom_drawer_options {
  background-color: transparent;
  top: auto;
  z-index: 1;

  :global {
    .ant-drawer-body {
      flex-grow: 1;
      margin: 1rem 1rem 1rem 1rem;
      padding: 0rem 0rem 0rem 0rem !important;
      overflow: auto;
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
    }

    .ant-drawer-header {
      position: relative !important;
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
      padding: 1.6rem 1rem !important;
      color: rgba(0, 0, 0, 0.85) !important;
      background: #fff !important;
      border-bottom: 1px solid #f0f0f0 !important;
      border-radius: 2px 2px 0 0 !important;
      left: auto !important;
      bottom: auto !important;
    }

    .ant-drawer-header-title {
      justify-content: flex-end !important;
    }

    .ant-drawer-mask {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 0 !important;
      opacity: 0 !important;
      pointer-events: none !important;
      transition: all 2s ease !important;
      -o-transition: all 2s ease !important;
      -ms-transition: all 2s ease !important;
      -moz-transition: all 2s ease !important;
      -webkit-transition: all 2s ease !important;
    }

    .ant-drawer.ant-drawer-open .ant-drawer-mask {
      height: 100% !important;
      transition: all 3s ease !important;
      -webkit-transition: all 2s ease-in-out !important;
      animation: none !important;
      pointer-events: all !important;
    }

    .ant-drawer-content {
      position: relative !important;
      z-index: 1 !important;
      overflow: auto !important;
      background-color: #fff !important;
      background-clip: padding-box !important;
      border: 0 !important;
      scrollbar-color: #5bbcf7 #fff !important;
      scrollbar-width: thin !important;
    }

    .ant-drawer-content-wrapper {
      box-shadow: 0px 20px 12px 2px rgba(0, 0, 0, 0.08) !important;
    }
  }
}

/* btn */
.button_add_blocks {
  width: 100%;
  display: flex;
  color: #fff;
  height: 3.4rem;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 15.8rem;
  align-items: center;
  border-radius: 0.5rem;
  justify-content: center;
  background-color: #5bbcf7;
  margin: 1rem;
}

/* table */

.container_options_table {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}
