#container_parrafos {
  font-size: 1.6rem;
  :global {
    p,
    em,
    h1,
    h2,
    h3,
    h4,
    span,
    table,
    blockquote {
      color: #7d7d7d !important;
      font-family: "Roboto" !important;
      background-color: #ffff !important;
    }
  }
  a{
    color: #3a38df !important;
  }
}

#pie_foto span {
  color: #ababab !important;
  font-size: 1.4rem !important;
  line-height: 1.3rem !important;
  margin-bottom: 0rem !important;
}

.container_text_pie {
  display: flex;
  align-items: center;
  margin-left: 1.8rem;
}

.container_content {
  gap: 2rem;
  width: 100%;
  margin: auto;
  padding: 1rem;
  display: flex;
  max-width: 96.2rem;
  margin-bottom: 5rem;
}

.container_text {
  width: 100%;
}

.container_spotify {
  width: 100%;
  max-width: 66.8rem;
  margin-bottom: 5.9rem;
}

.container_publicidad_uno {
  width: 100%;
  margin: 0 auto;
  max-width: 70.28rem;
  margin-bottom: 5.5rem;
  margin-top: 5.5rem;
}

.container_firma_general {
  display: flex;
  margin-bottom: 4.4rem;
  justify-content: space-between;
}

.container_foto_content {
  width: 100%;
  height: 22.1rem;
  object-fit: cover;
  margin-bottom: 2.8rem;
  background-position: center;
  background-repeat: no-repeat;
}

.container_embedded {
  width: 100%;
  height: 100%;
  max-width: 41.3rem;
  max-height: 38.6rem;
  margin-bottom: 3.9rem;
}

.firma {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 1.9rem;
}

.firma_noticia {
  color: #7d7d7d;
  font-size: 1.2rem;
}

.underline {
  cursor: pointer;
  text-decoration: underline;
}

.hr_spacing {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.card_module {
  display: flex;
  width: 100%;
  border-radius: 0.6rem;
  box-shadow: 0 1.1rem 1.7rem rgba(0, 0, 0, 0.08);
}

.container_interes {
  max-width: 100%;
}

.card_img {
  object-fit: cover;
  border-radius: 0.6rem;
  background-repeat: no-repeat;
  background-position: center;
}

.text_card {
  color: #7d7d7d;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.3rem;
}

.title_interes {
  color: #7d7d7d;
  font-size: 2.4rem;
  line-height: 2.7rem;
  font-family: "Gunterhaus";
}

.container_tex_interes {
  margin-bottom: 2.4rem;
}

.container_text_card {
  width: 100%;
  margin: auto;
}

/* Styles content dos */

.container_image_vertical {
  gap: 2rem;
}

.container_parrafo_vertical {
  width: 100%;
}

.line_pie_foto {
  width: 100%;
  border-left: 0.5px solid #ababab;
}

.container_pie_foto {
  margin-bottom: 2.8rem;
}

.container_foto_pie {
  width: 100%;
  margin-bottom: 1rem;
}

.container_img_vertical {
  width: 100%;
}

.container_related_banner {
  margin-top: 0rem;
}

/* styles content three */

.container_firma_especial {
  display: flex;
  margin-bottom: 4.4rem;
  align-items: center;
  justify-content: space-between;
}

.container_avatar {
  width: 3.9rem;
  height: 3.9rem;
  border-radius: 50%;
  -o-object-fit: contain;
  object-fit: contain;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container_firma_avatar {
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ImgContent {
  border-radius: 0.6rem;
}

/* styles content four */

.card_module_content_four {
  width: 100%;
  max-width: 95%;
  border-radius: 0.6rem;
  box-shadow: 0 1.1rem 1.7rem rgba(0, 0, 0, 0.08);
}

.container_text_content_four {
  width: 100%;
  max-width: 80%;
  margin: auto;
}
.container_content_four {
  gap: 2rem;
  width: 100%;
  margin: auto;
  padding: 1rem;
  display: flex;
  max-width: 100%;
  margin-bottom: 5rem;
}

.container_news_interest {
  display: flex;
  flex-direction: row;
}

.container_news {
  max-width: 32%;
  margin: 0.5rem;
}

.text_news_related {
  color: #0d3650;
  font-size: 2rem;
  font-weight: bold;
  line-height: 2.3rem;
}

.image_news_related {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  border-radius: 0.6rem;
}
/* Relacionadas */

.container_relacionadas {
  padding: 1rem;
}

.container_seccion {
  width: 100%;
  margin: auto;
  max-width: 21.9rem;
}

.text_seccion {
  color: #ababab;
  font-size: 1.2rem;
  line-height: 1.2rem;
  text-transform: uppercase;
}

.parrafo_seccion {
  color: #7d7d7d;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: bold;
  margin-bottom: 2.4rem;
}

.subparrafo_seccion {
  color: #7d7d7d;
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin-bottom: 2.4rem;
}

.noticias_relacionadas {
  color: #7d7d7d;
  font-size: 2.4rem;
  line-height: 2.7rem;
  margin-bottom: 1.1rem;
  font-family: Gunterhaus;
}

.hr_spacing_relacionada {
  margin-bottom: 2.2rem;
}

.container_image_relacionada {
  margin-bottom: 2rem;
}

/* Responsive */

@media (min-width: 576px) {
  .card_module {
    flex-direction: column;
  }

  .container_foto_content {
    height: 35.7rem;
  }
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .container_text {
    max-width: 68.8rem;
  }

  .container_image_vertical {
    display: flex;
  }

  .container_related_banner {
    margin-top: -13rem;
  }

  .text_card {
    font-size: 1.8rem;
  }

  .hr_spacing {
    margin-top: 5.7rem;
    margin-bottom: 5.7rem;
  }
}
