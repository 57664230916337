/* container */

.container_bg {
  background-color: #fff;
}

.container_bg_grey {
  width: 100%;
  margin: 0 auto;
  max-width: 1060px;
  background: #f2f2f2;
  padding: 2.4rem 6.6rem 0;
}

.container_title_border {
  display: flex;
  align-items: center;
}

.container_spacing {
  margin-bottom: 3.7rem;
}

.container_spacing_dos {
  padding-bottom: 5.2rem;
}

.container_new_block_two {
  width: 100%;
  max-width: 42.2rem;
}

.container_new {
  width: 100%;
  max-width: 50rem;
}

.container_span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container_module {
  width: 100%;
  gap: 0.9rem;
  height: 33.8rem;
  cursor: pointer;
  object-fit: contain;
  background-size: cover;
  border-radius: 0.6rem;
  background-position: center;
  background-repeat: no-repeat;
}

.container_module > div {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 4.6rem;
  border-radius: 0.6rem;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.08) 43.23%,
    #0000008c 65.62%
  );
}

.container_module_block {
  width: 100%;
  height: 30rem;
  cursor: pointer;
  object-fit: contain;
  background-size: cover;
  border-radius: 0.5rem;
  background-position: center;
  background-repeat: no-repeat;
}

/* estilos */

.title_module {
  font-family: "Gunterhaus";
  font-size: 3rem;
  color: #7d7d7d;
  line-height: 2.7rem;
}

.borde {
  width: 0.7rem;
  height: 2.6rem;
  border-radius: 0.6rem;
  margin-right: 1rem;
}

.module_five {
  margin-bottom: 2.5rem;
}

.title_section {
  font-weight: bold;
  font-size: 3rem;
  line-height: 3.2rem;
  color: #7d7d7d;
  margin-bottom: 2rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text_section {
  font-size: 1.6rem;
  line-height: 2rem;
  color: #7d7d7d;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.title_main {
  color: #ffffff;
  font-weight: 700;
  font-size: 3rem;
  overflow: hidden;
  line-height: 3.2rem;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}


.text_main {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.reading_main {
  color: #ffffff;
  font-size: 1rem;
}
