@import "~antd/dist/antd.css";
@import "variables.scss";

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  src: url(../assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gunterhaus";
  src: url(../assets/fonts/Gunterhaus/Gunterhaus-Transitional.otf) format("otf"),
    url(../assets/fonts/Gunterhaus/Gunterhaus-TransitionalItalic.otf)
      format("otf");
  font-style: normal;
  font-weight: normal;
}

html {
  font-size: 62.5% !important; /* 1 rem = 10px */
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  background-color: #fff !important;
  width: 16px !important;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  background-color: #fff !important;
}

*::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4 !important;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  background-color: #5bbcf7 !important;
  border-radius: 16px !important;
  border: 5px solid #fff !important;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5 !important;
  border: 4px solid #f4f4f4 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
  padding: 0;
}

img {
  width: 100%;
}

.ant-card-body {
  padding: 1rem;
}

.ant-card-bordered .ant-card-cover > img {
  width: 100%;
  height: 18.1rem;
  object-fit: cover;
  border-radius: 0.6rem;
}

.top_content_container {
  padding: 16px 24px;
  display: grid;
  grid-template-columns: 5% auto 10%;
  grid-column-gap: 10px;
}

.content_container {
  padding: 16px 24px;
}

// Antd Color
.ant-input {
  color: #ababab;
  font-size: 1.5rem;
  line-height: 2.4rem;
}

.ant-layout {
  background: #fff !important;
}

.ant-menu-submenu-title {
  color: #7d7d7d !important;
  padding-left: 5rem !important;
  overflow: hidden !important;
  line-height: 4rem !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-size: 1.4rem !important;
  line-height: 2.2rem !important;
}

.ant-menu-submenu-title:hover {
  color: #fff !important;
}

.ant-menu-submenu-arrow {
  position: absolute !important;
  top: 50% !important;
  right: 2rem !important;
  width: 2rem !important;
  color: rgba(0, 0, 0, 0.85) !important;
  transform: translateY(-50%) !important;
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-menu-submenu-arrow:hover {
  color: #fff !important;
}

.ant-menu-sub.ant-menu-inline {
  padding: 0 !important;
  background: #fff !important;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.ant-checkbox-inner {
  border: 1px solid #7d7d7d;
  border-radius: 0.5rem;
}

.ant-checkbox-inner:hover {
  border: 1px solid #7d7d7d;
}

.ant-collapse-header {
  font-size: 1.4rem !important;
  color: #7d7d7d !important;
  font-style: normal !important;
}

.ant-collapse-item-active .ant-collapse-header {
  font-weight: 600;
}

.ant-collapse-borderless {
  background: #ffffff !important;
}

.editorClassName {
  height: 400px !important;
}

.editorClassName::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
.editorClassName::-webkit-scrollbar-track {
  background-color: #fff;
}
.editorClassName::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.editorClassName::-webkit-scrollbar-thumb {
  background-color: #5bbcf7;
  border-radius: 16px;
  border: 5px solid #fff;
}
.editorClassName::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

.ant-select:hover,
.ant-select:focus {
  border-color: #7d7d7d !important;
}

.ant-btn-primary {
  background: $primary-color;
  border-color: $primary-color;
  &:hover,
  &:focus {
    background: $primary-color;
    border-color: $primary-color;
    opacity: 0.8;
  }
}
li.ant-menu-item-selected {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.ant-pagination-item-active,
.ant-pagination-item-active a {
  color: $primary-color;
  border-color: $primary-color;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(25, 148, 121, 0.25) !important;
}
.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
  border-right: 3px solid #199479 !important;
}

.ant-select {
  border: 1px solid #7d7d7d !important;
  border-radius: 0.5rem !important;
  color: #ababab !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 0.5rem !important;
}

/* css que trae react */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-drawer-body {
  flex-grow: 1;
  margin: 5rem 1rem 0rem 1rem;
  padding: 0rem 0rem 1rem 0rem !important;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-drawer-header {
  position: relative !important;
  display: flex !important;
  bottom: -45px !important;
  left: -10px !important;
  align-items: center;
  justify-content: flex-end !important;
  padding: 0rem !important;
  color: rgba(0, 0, 0, 0.85) !important;
  background: none !important;
}

.ant-drawer-header-title {
  justify-content: flex-end !important;
}

.ant-drawer-mask {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 0 !important;
  opacity: 0 !important;
  pointer-events: none !important;
  transition: all 2s ease !important;
  -o-transition: all 2s ease !important;
  -ms-transition: all 2s ease !important;
  -moz-transition: all 2s ease !important;
  -webkit-transition: all 2s ease !important;
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100% !important;
  transition: all 3s ease !important;
  -webkit-transition: all 2s ease-in-out !important;
  animation: none !important;
  pointer-events: all !important;
}

.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  left: 25.7rem !important;
}

.ant-drawer-content {
  position: relative !important;
  z-index: 1 !important;
  overflow: auto !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 0 !important;
  scrollbar-color: #5bbcf7 #fff !important;
  scrollbar-width: thin !important;
}

.ant-drawer-body::-webkit-scrollbar {
  background-color: #fff !important;
  width: 16px !important;
}

/* background of the scrollbar except button or resizer */
.ant-drawer-body::-webkit-scrollbar-track {
  background-color: #fff !important;
}

.ant-drawer-body::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4 !important;
}

/* scrollbar itself */
.ant-drawer-body::-webkit-scrollbar-thumb {
  background-color: #5bbcf7 !important;
  border-radius: 16px !important;
  border: 5px solid #fff !important;
}

.ant-drawer-body::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5 !important;
  border: 4px solid #f4f4f4 !important;
}

.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.editor_container p,
.editor_container em,
.editor_container h1,
.editor_container h2,
.editor_container h3,
.editor_container h4,
.editor_container span,
.editor_container table,
.editor_container blockquote {
  font-family: "Roboto" !important;
  color: #7d7d7d !important;
  background-color: #ffff !important;
}

.editor_container_pie p,
.editor_container_pie em,
.editor_container_pie h1,
.editor_container_pieh2,
.editor_container_pie h3,
.editor_container_pieh4,
.editor_container_pie span,
.editor_container_pie table,
.editor_container_pie blockquote {
  font-family: "Roboto" !important;
  background-color: #ffff !important;
}

.editor_container_pie {
  max-height: 500px !important;
}

.editor_container {
  max-height: 500px !important;
}
