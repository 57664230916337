/* Styles global new notice */

.container_new_news {
  width: 100%;
  padding: 1.6rem;
}

.line_new_news {
  width: 100%;
  padding-bottom: 1rem;
  border-bottom: 0.5px solid #d9d9d9;
}

.text_new_news {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding-bottom: 1rem;
}

/* Styles content menu */

.select_seccion {
  width: 100%;
  color: #ababab !important;
}

.option_select {
  color: #ababab !important;
  background: #fff !important;
}

.option_select:hover {
  background: #f6f6f6 !important;
}

.span_select {
  font-size: 1.3rem;
  color: #7d7d7d;
  line-height: 19px;
}

.container_button {
  padding: 1rem 0 0 0;
}

.container_button_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
}

.container_button_collapse {
  display: flex;
  margin: 2rem;
  align-items: center;
  justify-content: center;
}

.save_button_notices {
  width: 100%;
  color: #fff;
  height: 3.2rem;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 13.4rem;
  margin-right: 1.6rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.save_button_notices:hover {
  color: #5bbcf7;
  background: #ffffff;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button_notices {
  width: 100%;
  height: 3.2rem;
  color: #5bbcf7;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 13.4rem;
  background: #ffffff;
  border-radius: 0.5rem;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button_notices:hover {
  background-color: #5bbcf7;
  color: #fff;
}

.inputs {
  height: 4rem;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  box-sizing: border-box;
  border-radius: 0.5rem;
}

.inputs_select {
  width: 100%;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  margin: 0.5rem 0 1rem 0;
}

.inputs_select:hover,
.inputs_select:focus {
  border-color: #7d7d7d !important;
}

.inputs:hover,
.inputs:focus {
  border-color: #7d7d7d !important;
}

.spacing_item {
  margin-bottom: 0 !important;
}

/* Styles upload */

.container_upload {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 1rem 0;
}

.span_text {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 0rem 1.5rem 3rem 1.5rem;
}

.span_text_cargar {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 2.4rem;
  padding: 0rem 14.5rem 1rem 0rem;
}

.text_color {
  color: #7d7d7d;
  font-size: 1.4rem;
}

.text_color_switch {
  color: #7d7d7d;
  font-size: 1.4rem;
  padding-right: 0.5rem;
}

.text_upload {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-align: center;
  padding: 0.9rem 2.5rem 1rem 2.5rem;
}

.edit_tex_notices {
  color: rgba(0, 0, 0, 0.25);
  font-size: 1.4rem;
  line-height: 2.4rem;
}

/* Styles selects */

.container_search {
  display: flex;
  padding: 1rem;
  align-items: center;
}

.container_selects {
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: center;
}

.spacing_select_fecha {
  width: 14.3rem !important;
  margin-right: 1rem;
}

.spacing_select_claves {
  width: 18.3rem !important;
  margin-right: 1rem;
}

.iconClose {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
  margin-top: 1rem;
}

.spacing_select_seccion {
  width: 15.3rem !important;
  margin-right: 1rem;
}

.search_select {
  width: 30rem !important;
  background: #ffffff;
}

.dropdown_select {
  width: 45rem !important;
}

.search_button {
  width: 100%;
  max-width: 8.9rem;
  height: 3.4rem;
  color: #fff;
  box-shadow: none;
  font-size: 1.4rem;
  font-weight: 600;
  margin-left: 1.6rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

/* Styles post notices */

.line_notices {
  width: 100%;
  padding-bottom: 1rem;
  margin-top: 5.6rem;
  border-bottom: 0.5px solid #d9d9d9;
}

.container_notices {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container_search_table {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 2rem 0 2rem 0;
}

.input_search {
  width: 16.7rem;
  height: 2.9rem;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  box-sizing: border-box;
  border-radius: 0.5rem;
}

.container_options {
  display: flex;
  gap: 2rem;
}

.text_search {
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: #7d7d7d;
}

.pagination {
  color: #7d7d7d;
  font-size: 1.4rem;
}

.container_table {
  height: 100vh;
  width: 100%;
  overflow: auto;
}

.container_table::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

// styles calender post notices

.container_item_calendar {
  display: table;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

.container_item_calendar p {
  text-align: center;
  vertical-align: middle;
  display: table-cell;
  font-weight: bold;
}

/* background of the scrollbar except button or resizer */
/* .container_table::-webkit-scrollbar-track {
  background-color: #fff;
}
.container_table::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
} */

/* scrollbar itself */
/* .container_table::-webkit-scrollbar-thumb {
  background-color: #5bbcf7;
  border-radius: 16px;
  border: 5px solid #fff;
}
.container_table::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
} */

.information_table {
  :global {
    .ant-table-thead > tr > th {
      background-color: #f9f9f9;
      color: #7d7d7d;
      font-weight: bold;
    }
    .ant-table-tbody > tr > td {
      color: #7d7d7d;
    }
  }
}

.container_icons {
  gap: 1rem;
  display: flex;
}

.icons_table {
  width: 100%;
  height: 100%;
  cursor: pointer;
  max-width: 1.4rem;
  max-height: 1.4rem;
}

.container_button_table_notices {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
}

.button_notices_table {
  display: flex;
  width: 100%;
  height: 3rem;
  color: #5bbcf7;
  box-shadow: none;
  font-size: 1.4rem;
  max-width: 10rem;
  background: #ffffff;
  border-radius: 0.5rem;
  justify-content: center;
  border: 0.1rem solid #5bbcf7;
}

.button_notices_table:hover {
  background-color: #5bbcf7;
  color: #fff;
}

.container_content_modal {
  display: flex;
  flex-direction: column;
  padding: 2.8rem 1rem 0 2.1rem;
}

.line_table_modal {
  width: 100%;
  margin: 2.3rem 0 1.6rem;
  border-bottom: 0.5px solid #d9d9d9;
}

.container_option_home {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.container_switch {
  gap: 0.4rem;
  display: flex;
  align-items: center;
}

.container_buttons {
  gap: 2rem;
  display: flex;
  align-items: center;
}

.title_table {
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #7d7d7d;
  margin-bottom: 1.7rem;
}

.text_table {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #7d7d7d;
}

.text_table_option {
  color: #7d7d7d;
  font-size: 1.4rem;
  line-height: 1.8rem;
  padding-right: 15.1rem;
}

.text_title_home {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #7d7d7d;
  margin: 1rem 1rem 1.4rem 1rem;
}

.custom_form_item {
  margin-bottom: 0 !important;
}

.custom_form_item_ten {
  margin-bottom: 1rem !important;
}

/* Collapse */
.custom_collapse_manual {
  background-color: #5bbcf7;
}

/* Styles espacio */

:global {
  .ant-radio-button-wrapper {
    max-width: 120px;
    width: 100%;
    max-height: 140px;
    height: 100%;
    left: 0 !important;
    padding: 0;
  }

  .ant-radio-button-wrapper span {
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  span .ant-image {
    display: flex !important;
    align-items: center;
    padding: 0px 12px 0px 12px;
    height: 100px;
  }
}

.padding_subcollapse {
  :global {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 0;
    }
  }
}

.padding_subcollapse_with {
  :global {
    .ant-collapse-content > .ant-collapse-content-box {
      padding: 16px;
    }
  }
}

.container_buttons {
  padding: 1rem 16px;
  padding-left: 0rem;
}

.header_espacio {
  :global {
    .ant-collapse-header {
      padding: 12px 0 !important;
    }
  }
}

.text_module {
  text-align: center;
  font-size: 1.3rem;
  color: #ababab;
}

.w_img {
  width: 89px !important;
}

.container_collapse {
  margin: 1rem;
}

.container_1440 {
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
}
