/* container */

.container_bg {
  background-color: #fff;
}

.container_1440 {
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
}

.container_text {
  width: 100%;
  margin: 0 auto;
  max-width: 95.2rem;
}

.container_video {
  width: 100%;
  height: 71.2rem;
  cursor: pointer;
  object-fit: cover;
  border-radius: 0.6rem;
  background-position: center;
  background-repeat: no-repeat;
}

.container_video > div {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 3.8rem;
  align-items: center;
  border-radius: 0.6rem;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.08) 43.23%,
    #0000008c 65.62%
  );
}

/* estilos */

.title_module {
  font-family: "Gunterhaus";
  font-size: 3rem;
  color: #103783;
  line-height: 2.7rem;
}

.title_main {
  font-size: 4.8rem;
  color: #ffffff;
  font-weight: bold;
  line-height: 5.6rem;
  margin-bottom: 2.4rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.subtitle_main {
  color: #FFFFFF;
  font-size: 2.4rem;
  line-height: 2.8rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
