.container_seleccionar_noticia {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container_selects {
  display: flex;
  align-items: center;
  margin: 1rem 3rem 0 3rem;
}

.container_search {
  display: flex;
  align-items: center;
  margin: 1rem 0 2rem 3rem;
}

.spacing_item {
  margin-bottom: 0 !important;
}

.span_select {
  font-size: 1.3rem;
  color: #7d7d7d;
  line-height: 19px;
}

.inputs_select {
  width: 100% !important;
  background: #ffffff;
  border: 1px solid #7d7d7d;
  margin: 0.5rem 0 1rem 0;
}

.inputs_select:hover,
.inputs_select:focus {
  border-color: #7d7d7d !important;
}

.dropdown_select {
  width: 571px !important;
}

.spacing_select_fecha {
  width: 14.3rem !important;
  margin-right: 1rem;
}

.select_seccion {
  width: 100%;
  color: #ababab !important;
}

.option_select {
  color: #ababab !important;
  background: #fff !important;
}

.option_select:hover {
  background: #f6f6f6 !important;
}

.spacing_select_claves {
  width: 18.3rem !important;
  margin-right: 1rem;
}

.iconClose {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
  margin-top: 1rem;
}

.search_select {
  width: 30rem !important;
  background: #ffffff;
}

.search_button {
  width: 100%;
  max-width: 8.9rem;
  height: 3.4rem;
  color: #fff;
  box-shadow: none;
  font-size: 1.4rem;
  font-weight: 600;
  margin-left: 1.6rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.container_button_menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.save_button {
  width: 100%;
  color: #fff;
  height: 3.2rem;
  box-shadow: none;
  font-size: 1.4rem;
  margin-right: 1.6rem;
  border-radius: 0.5rem;
  background-color: #5bbcf7;
}

.save_button:hover {
  color: #5bbcf7;
  background: #ffffff;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button {
  width: 100%;
  height: 3.2rem;
  color: #5bbcf7;
  box-shadow: none;
  font-size: 1.4rem;
  background: #ffffff;
  border-radius: 0.5rem;
  border: 0.1rem solid #5bbcf7;
}

.cancel_button:hover {
  background-color: #5bbcf7;
  color: #fff;
}

/* data select */
.notice_title {
  color: #7d7d7d;
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
