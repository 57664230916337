/* contenedores */

.container_bg {
  background-color: #fff;
}

.container_1060 {
  width: 100%;
  margin: 0 auto;
  max-width: 1060px;
  background-color: #fff;
}

.container_block_one {
  margin-bottom: 2rem;
}

.container_small {
  width: 100%;
  height: 16.3rem;
  cursor: pointer;
  object-fit: contain;
  margin-bottom: 1.2rem;
  border-radius: 0.6rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container_big_img {
  width: 100%;
  height: 40.2rem;
  cursor: pointer;
  object-fit: contain;
  border-radius: 0.6rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.container_big_img > div {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 4.6rem;
  border-radius: 0.6rem;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.08) 43.23%,
    #0000008c 65.62%
  );
}

.container_span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container_text_card {
  padding: 1rem;
}

.container_card {
  width: 100%;
  cursor: pointer;
  height: 32.2rem;
  border-radius: 0.6rem;
  box-shadow: 0 1.1rem 1.7rem rgba(0, 0, 0, 0.08);
}

/* estilos */

.line_section {
  margin: 1rem 0;
  border: 0.5px solid #6a6a6a;
}

.title_module {
  font-family: "Gunterhaus";
  font-size: 3rem;
  color: #103783;
  line-height: 2.7rem;
}

.text_section {
  font-size: 1.2rem;
  color: #d1d1d1;
  line-height: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
}

.description_title {
  color: #7d7d7d;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.9rem;
  margin-bottom: 1rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.description_subtitle {
  color: #7d7d7d;
  font-size: 1.6rem;
  line-height: 1.9rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.reading_section {
  font-size: 1rem;
  color: #ababab;
  line-height: 1.3rem;
  margin-bottom: 0.6rem;
}

.title_main {
  color: #ffffff;
  font-weight: 700;
  font-size: 3rem;
  overflow: hidden;
  line-height: 3.2rem;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text_main {
  color: #ffffff;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.reading_main {
  color: #ffffff;
  font-size: 1rem;
}

.title_card {
  color: #7d7d7d;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text_card {
  color: #7d7d7d;
  font-size: 1.8rem;
  line-height: 2rem;
  overflow: hidden;
  display: -webkit-box;
  overflow-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
